
import { reactive, ref, toRefs, defineComponent, onMounted, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {
    regionList,
    delRegion,
    editRegion,
    createRegion,
    regionInfo,
    roomList,
    deletetaskrw,
    createTasks,
    Operationtasks,
    newroomlist,
    getmjpmac,
    robotelevator
} from '@/apis/region';
import internal from 'stream';
import { useStore } from '@/store';
import { getAllUser, addUser } from '@/apis/user';
import Killtime from '@/components/killtime/index.vue';
import i18n from '@/locales';
import Moment from 'moment';
import { ElMessage } from 'element-plus';
import { debounce, throttle } from 'lodash';
// const newdate = new  Date()
interface timingfaces {
    paramshttp?: Object;
    //定义点击房间后传递的字段为对象
    roomsobj: any;
    options: Array<any>;
    radio1: String;
    checked1: Boolean;
    checked2: Boolean;
    countdown: any;
    Theinvasion: any;
    selectcountdown: Array<String>;
    selectTheinvasion: Array<String>;
    explorelist: Array<String>;
    num: Number;
    explonum: number;
    radio2: String;
    value1: any;
    value2: any;
    Datevalue1: Array<any>;
    sn: any;
    weekdays: Array<String>;
    exploresion: string | number;
    getfloor: number;
    getfloorarray: Array<number>;
    getflooron: boolean;
    mode: number;
    addoperatorflge: boolean;
    operatorname: any;
    opertorlist: Array<any>;
    opeatorfalge: boolean;
    addopeatorsname: string;
}
export default defineComponent({
    components: {
        Killtime
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const timingstates: timingfaces = reactive({
            sn: route.params.sn,
            paramshttp: {
                page: 1,
                pageSize: 100,
                limit: true,
                areaName: ''
            },
            value: [],
            options: [],
            roomsobj: {},
            radio1: '',
            checked1: true,
            checked2: true,
            Datevalue1: [],
            AlternativeDate: [
                {
                    value: '0',
                    label: i18n.global.t('creted.zhouri')
                },
                {
                    value: '1',
                    label: i18n.global.t('creted.zhouyi')
                },
                {
                    value: '2',
                    label: i18n.global.t('creted.zhouer')
                },
                {
                    value: '3',
                    label: i18n.global.t('creted.zhousan')
                },
                {
                    value: '4',
                    label: i18n.global.t('creted.zhousi')
                },
                {
                    value: '5',
                    label: i18n.global.t('creted.zhouwu')
                },
                {
                    value: '6',
                    label: i18n.global.t('creted.zhouliu')
                }
            ],
            selectcountdown: ['5s', '10s', '15s', '20s', '25s', '30s'],
            countdown: '20s',
            selectTheinvasion: ['1min', '3min', '5min', '10min'],
            explorelist: ['30s', '1min', '2min', '3min', '5min'],
            exploresion: '',
            Theinvasion: '5min',
            num: 5,
            explonum: 60,
            radio2: 'once',
            value2: new Date(new Date().setHours(new Date().getHours() + 1)),
            value1: new Date(new Date().setHours(new Date().getHours() + 1)),
            weekdays: [],
            getfloor: 1,
            getfloorarray: [],
            getflooron: true,
            mode: 1,
            addoperatorflge: false,
            opertorlist: [],
            operatorname: store.state.user.name,
            opeatorfalge: false,
            addopeatorsname: ''
            // Gotothetop() {
            //     router.go(-1)
            // }
        });
        const methods = reactive({
            getusers() {
                getAllUser({
                    userName: '',
                    authority: '',
                    negative: '',
                    pageSize: 1000,
                    page: 1
                }).then((res: any) => {
                    console.log(res);
                    timingstates.opertorlist = res.data.lists;
                });
            },
            // 新增操作人按钮确认
            addUseropertaor() {
                if (timingstates.addopeatorsname.length < 6 || timingstates.addopeatorsname.length > 20) {
                    ElMessage({
                        message: i18n.global.t('user.usernameRule2'),
                        type: 'error'
                    });
                    return;
                }
                addUser({ username: timingstates.addopeatorsname, password: '123456' }).then((res: any) => {
                    console.log(res);
                    if (res.code == 200) {
                        timingstates.opeatorfalge = false;
                        timingstates.operatorname = timingstates.addopeatorsname;
                        timingstates.addopeatorsname = '';
                        methods.getusers();
                        ElMessage({
                            message: i18n.global.t('system.createSuccess'),
                            type: 'success'
                        });
                    } else if (res.code == 100013) {
                        ElMessage({
                            message: i18n.global.t('user.repeatUser'),
                            type: 'error'
                        });
                    }
                });
            },
            // 取消新增操作人
            cancelDialog() {
                timingstates.opeatorfalge = false;
                timingstates.addopeatorsname = '';
            },
            // 点击后设置操作人
            setusernames(ele: any) {
                if (ele.negative == 1) {
                    return;
                }
                timingstates.operatorname = ele.username == '' ? ele.email : ele.username;
                timingstates.addoperatorflge = false;
            },
            getelevator(ele: string) {
                robotelevator({
                    sn: timingstates.sn,
                    name: ele
                }).then((res: any) => {
                    console.log(JSON.parse(res.data));
                    let ress = JSON.parse(res.data);
                    if (ele === 'ele_floor') {
                        methods.getfloorsmax(Number(ress.content.value));
                    } else {
                        timingstates.getflooron = ress.content.value === 'on' ? true : false;
                    }
                });
            },
            godashboards() {
                router.push('/dashboard');
            },
            gotorobotsn() {
                router.push('/robot/robotInfo/' + timingstates.sn);
            },
            killtrue(): void {
                Killtimes.value.timeflage = true;
            },
            timesvaluedurion(value1: number, value2: number) {
                timingstates.explonum = value1 * 60 + value2;
                timingstates.exploresion = value1 + 'min' + value2 + 'S';
                console.log(timingstates.explonum);
            },
            //  getchecked1falge():void {
            //      getmjpmac({
            //        sn:route.params.sn,
            //        option:'connected'
            //      }).then((res:any)=>{
            //        if (JSON.parse(res.data)[0].mac === '') {
            //           timingstates.checked1falge = true
            //           timingstates.checked1 = false
            //        }
            //      })
            // },
            // 获取区域及区域下的所有房间 手动添加上value 和label字段
            getquyus: (): void => {
                regionList(timingstates.paramshttp).then((res: any) => {
                    console.log(res.data.lists);
                    timingstates['options'] = res.data.lists;
                    timingstates['options'].forEach((ele: any, index: number) => {
                        timingstates['options'][index].value = timingstates['options'][index].ID;
                        timingstates['options'][index].label = timingstates['options'][index].area_name;
                        // roomList({
                        //   page: 1,
                        //   pageSize: 100,
                        //   limit: true,
                        //   roomName: "",
                        //   areaid: timingstates["options"][index].ID,
                        // }).then((ress: any) => {
                        //   console.log(ress);
                        //   timingstates["options"][index]["children"] = [];
                        //   if (ress.data.lists.length > 0) {
                        //     ress.data.lists.forEach((eless: any, indexs: number) => {
                        //       timingstates["options"][index]["children"][indexs] = eless;
                        //       timingstates["options"][index]["children"][indexs].label =
                        //         eless.RoomName;
                        //       timingstates["options"][index]["children"][indexs].value = {
                        //         roomid: eless.ID,
                        //         areaid: eless.AreaID,
                        //       };
                        //     });
                        //   }
                        newroomlist({
                            page: 0,
                            pageSize: 100,
                            sn: timingstates.sn,
                            areaid: timingstates['options'][index].ID
                        }).then((ress: any) => {
                            console.log(JSON.parse(ress.data));
                            timingstates['options'][index]['children'] = [];
                            if (JSON.parse(ress.data).content.list.length > 0) {
                                JSON.parse(ress.data).content.list.forEach((eless: any, indexs: number) => {
                                    timingstates['options'][index]['children'][indexs] = eless;
                                    timingstates['options'][index]['children'][indexs].label = eless.name;
                                    timingstates['options'][index]['children'][indexs].value = {
                                        roomid: eless.area_id === 0 ? eless.id : eless.yun_id,
                                        areaid: eless.area_id,
                                        mapnamas: eless.map
                                    };
                                });
                            }
                        });
                    });
                });
            },
            // 点击切换倒计时
            countselet: (values: string) => {
                console.log(values);
                timingstates.countdown = values;
            },
            // 点击切换探索模式下时间
            exploresionselet: (explorevalue: string) => {
                console.log(explorevalue);
                if (parseInt(explorevalue) != 30) {
                    timingstates.explonum = parseInt(explorevalue) * 60;
                } else {
                    timingstates.explonum = parseInt(explorevalue);
                }
            },
            Theinvasionselet: (values: string) => {
                console.log(values);
                timingstates.Theinvasion = values;
            },
            // 选择房间后判断
            handleChange: (value: any): void => {
                console.log(value);
                // states .roomsobj  = {ajda:1}
                if (!value[1]) {
                    ElMessage({
                        message: i18n.global.t('disinfect.dqmyfj')
                    });
                } else {
                    timingstates.roomsobj = value[1];
                }
            },
            // raiofn: (vlues) => {
            //   console.log(vlues, states.radio1);
            // },
            getnedatefn: (getriqi: any): void => {
                console.log(getriqi, Moment(getriqi).format('YYYY-MM-DD'));
                timingstates.value1 = getriqi;
            },
            getfenzhongfn: (getriqi: any): void => {
                console.log(getriqi, Moment(getriqi).format('HH:mm:ss'));
                timingstates.value2 = getriqi;
            },
            selectdata: (values: any) => {
                console.log(values);
                timingstates.weekdays = values;
            },
            stabilization: debounce((Needtooffer) => Needtooffer(), 2000, { leading: true, trailing: false }),
            newtasks: () => {
                if (timingstates.radio2 != 'once' && timingstates.weekdays.length <= 0) {
                    ElMessage({
                        message: i18n.global.t('creted.qxzcfsj')
                    });
                    return;
                }
                const restask: Object = {
                    mode: timingstates.mode,
                    area_id: timingstates.roomsobj.areaid,
                    auto_finish: parseInt(timingstates.Theinvasion) * 60,
                    countdown: parseInt(timingstates.countdown),
                    cycle: timingstates.radio2,
                    days: timingstates.radio2 === 'once' ? [] : Array.from(timingstates.weekdays),
                    duration: timingstates.radio1 === 'explore_disinfect' ? timingstates.explonum : null,
                    flags: (timingstates.checked1 ? 1 : 0) | (timingstates.checked2 ? 2 : 0),
                    map_name: timingstates.radio1 === 'deploy_disinfect' ? timingstates.roomsobj.mapnamas : '',
                    room_id: timingstates.roomsobj.roomid,
                    type: timingstates.radio1,
                    time: Moment(timingstates.value2).format('HH:mm:ss'),
                    date:
                        timingstates.radio2 === 'once'
                            ? Moment(timingstates.value1).format('YYYY-MM-DD')
                            : Moment(new Date(new Date().setHours(new Date().getHours() + 1))).format('YYYY-MM-DD'),
                    destination: String(timingstates.getfloor),
                    operator: timingstates.operatorname
                };
                console.log(restask);
                createTasks(route.params.sn, restask).then((res: any) => {
                    // console.log(res)
                    if (res.code === 200) {
                        Operationtasks(res.data.taskid, 'start', timingstates.sn, {})
                            .then((ress: any) => {
                                //  console.log(ress)
                                if (JSON.parse(ress.data).content.code === 0) {
                                    ElMessage({
                                        message: i18n.global.t('creted.cjcgzztz'),
                                        type: 'success'
                                    });
                                    router.replace(`/robot/robotInfo/${route.params.sn}`);
                                } else if (JSON.parse(ress.data).content.code === 11010031) {
                                    ElMessage({
                                        message: i18n.global.t('creted.mjpwljcjsb')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010023) {
                                    ElMessage({
                                        message: i18n.global.t('creted.dqyjxrw')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010028) {
                                    ElMessage({
                                        message: i18n.global.t('creted.xtlbcwcjsb')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010032) {
                                    ElMessage({
                                        message: i18n.global.t('creted.sbgzcjsb')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010030) {
                                    ElMessage({
                                        message: i18n.global.t('creted.jqjtqbcan')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010033) {
                                    ElMessage({
                                        message: i18n.global.t('creted.dcyccjsb')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010034) {
                                    ElMessage({
                                        message: i18n.global.t('creted.fztyccjsb')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010035) {
                                    ElMessage({
                                        message: i18n.global.t('creted.dpdjglcjsb')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010036) {
                                    ElMessage({
                                        message: i18n.global.t('creted.zdyccjsb')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010037) {
                                    ElMessage({
                                        message: i18n.global.t('creted.zczcjsb')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010038) {
                                    ElMessage({
                                        message: i18n.global.t('creted.xczcjsb')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010042) {
                                    ElMessage({
                                        message: i18n.global.t('creted.dqdtbczwfcj')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010045) {
                                    ElMessage({
                                        message: i18n.global.t('creted.dhmkyxzcjsb')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010046) {
                                    ElMessage({
                                        message: i18n.global.t('creted.cgqyccjsb')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010048) {
                                    ElMessage({
                                        message: i18n.global.t('creted.jqrwdggcjsb')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010049) {
                                    ElMessage({
                                        message: i18n.global.t('creted.jqrdlxy')
                                    });
                                    deletetaskrw(res.data.taskid, {});
                                } else if (JSON.parse(ress.data).content.code === 11010040) {
                                    deletetaskrw(res.data.taskid, {});
                                    ElMessage({
                                        message: i18n.global.t('dsrw.rwsjct')
                                    });
                                } else if (JSON.parse(ress.data).content.code === 11010041) {
                                    deletetaskrw(res.data.taskid, {});
                                    ElMessage({
                                        message: i18n.global.t('dsrw.rwjgq')
                                    });
                                } else if (JSON.parse(ress.data).content.code === 11010014) {
                                    deletetaskrw(res.data.taskid, {});
                                    ElMessage({
                                        message: i18n.global.t('dsrw.cjsb')
                                    });
                                } else {
                                    deletetaskrw(res.data.taskid, {});
                                }
                            })
                            .catch((err: any) => {
                                deletetaskrw(res.data.taskid, {});
                            });
                    }
                });
            },
            getfloorsmax(valsa: number) {
                for (let i = 1; i <= valsa; i++) {
                    timingstates.getfloorarray.push(i);
                }
            },
            setfloorcs(values: number) {
                timingstates.getfloor = values;
                console.log(timingstates.getfloor);
            }
        });

        let Killtimes = ref<any | HTMLElement>(null);
        onMounted(() => {
            //调用区域
            methods.getquyus();
            methods.getelevator('elevator');
            methods.getelevator('ele_floor');
            methods.getusers();
            // methods.getchecked1falge()
            //   let t = methods.createTimeStr(new Date(), 'ymd') + ' 00:03:00'
            // timingstates.value2 = new Date(new Date().setHours(new Date().getHours() + 1));
            // timingstates.value2 = new Date(2016, 9, 10, 18, 40)
            // timingstates.value1 = new Date(new Date().setHours(new Date().getHours() + 1))
            nextTick(() => {
                // console.log(Killtimes.value.timeflage)
                timingstates.explonum = Killtimes.value.overindex * 60 + Killtimes.value.overmiaoindex;
                timingstates.exploresion = Killtimes.value.overindex + 'min' + Killtimes.value.overmiaoindex + 'S';
            });
        });
        return {
            ...toRefs(timingstates),
            ...toRefs(methods),
            debounce,
            store,
            Killtimes
        };
    }
});
